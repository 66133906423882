import * as React from "react";

const RustEffect = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "30px",
      }}
    >
      <h1
        style={{ textAlign: "center", fontSize: "36px", marginBottom: "30px" }}
      >
        Rust effect
      </h1>
      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        Corot Rust Effect Decor is a decorative painting technique that combines
        the Corot Decor technique with a rust effect finish. This technique
        involves layering different colors and textures to create a soft,
        naturalistic look that resembles the landscapes and scenes depicted in
        Corot's paintings and adding a rustic touch to it. The rust effect is
        achieved by using special pigments and patinas that are applied to the
        surface to create the appearance of rust and aging. The final result is
        a unique, one-of-a-kind finish that can be used to create a variety of
        different looks, including rustic, naturalistic, and romantic, with a
        rustic touch. This technique can be applied on various materials like
        metal, wood, plaster, and more. It's often used in residential spaces
        such as living rooms, bedrooms, kitchens and bathrooms, but can also be
        used in commercial spaces, such as restaurants and hotels. In
        conclusion, Corot Rust Effect Decor is a decorative painting technique
        that combines the Corot Decor technique with a rust effect finish. It
        can be used to create a unique, naturalistic look with a rustic touch in
        any space. The technique is time-consuming and skill-dependent and may
        not be suitable for all types of spaces or styles and it's not a widely
        known technique.
      </p>
    </div>
  );
};

export default RustEffect;
